@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
:focus-visible{
  outline: unset;
}
code {
  font-family: 'Roboto', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Archivo", sans-serif;
}
.font-roboto{
  font-family: 'Roboto', sans-serif;
}
.font-archivo{
  font-family: "Archivo", sans-serif;
}
.text-gradient{
  -webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}